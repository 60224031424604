/** @jsx jsx */
import { Component, Fragment } from "react"
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { PartnersBlock, ArticlesBlock } from "x"

import { TheSeo } from "x"

// import * as builderComponents from "../BuilderComponents"

// const componentNamePattern = 'Craft_ContentBuilderPro'

// function getComponent(__typename) {
// 	const componentName = __typename.replace(componentNamePattern, '')
// 	return builderComponents[componentName] || false
// }

// Craft_ContentBuilderProArticles

export default class Pages extends Component {
	render() {
		const {
			data: {
				craft: { entry },
			},
			pageContext: { ...pageContext },
		} = this.props

		return (
			<Fragment>
				<TheSeo title={entry.title} />
				<Box>
					{entry.contentBuilderPro[0].__typename ===
					"Craft_ContentBuilderProPartners" ? (
						<PartnersBlock />
					) : entry.contentBuilderPro[0].__typename ===
					  "Craft_ContentBuilderProArticles" ? (
						<ArticlesBlock />
					) : (
						"nope"
					)}
				</Box>
			</Fragment>
		)
	}
}

export const pagesQuery = graphql`
	query QueryPages($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Pages {
					title
					id
					url
					slug
					uri
					... on Craft_Pages {
						pageInfo {
							headline
							theme
							lightNav
							hideTitle
							noRobots
							headerOverlay
							prescrollNav
							postscrollNav
						}
						contentBuilderPro {
							__typename
						}
					}
				}
			}
		}
	}
`
